.App {
  text-align: center;
}
#expenseForm input {
  text-align: right;
}
#expenseForm select,
.selectbox select {
  font-size: 20px;
  height: 40px;
  max-width: 100%;
  min-width: 100%;
}
.subtable th {
  background: #00b4aa !important;
}
.needbill {
  background: #ff0000 !important;
}
#expenseForm > div > div > div:nth-child(2),
#expenseForm > div > div > div:nth-child(2) > div,
#expenseForm > div > div > div:nth-child(2) > div label {
  width: 100%;
  min-width: 0;
  max-width: 100%;
}
input[type="file"] {
  display: none;
}
.MuiButton-contained.warning {
  background: #ff0000 !important;
}
.markerLabel {
  background: #ff0000;
  color: #fff;
}
.xmluploader .file-types {
  display: block;
  clear: both;
}
.xmluploader > label {
  min-height: 200px;
}
.datePick {
  height: 40px;
}
.accepted {
  background: #ecffdb;
}
.tabpanelmark > div > div:nth-child(2) div {
  background: none !important;
}
.custom-file-upload {
  border: none;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24.5px;
  background: #202844 !important;
  color: #fff;
  text-align: center;
  width: 100%;
}
.MuiTooltip-tooltip {
  max-width: 500px !important;
}
.rmdp-top-class {
  background: #fff;
}
.rmdp-calendar {
  box-shadow: 0 0 5px #8798ad;
}
.invoicegroup .MuiGrid-item {
  margin-top: 5px;
  word-wrap: break-word;
}
#expenseForm .MuiGrid-item {
  padding-top: 7px;
}
#expenseForm #submitButton {
  padding-top: 20px;
}
#expenseForm .takePhoto {
  width: 100%;
  background: #202844 !important;
}
#totalExpense > div {
  background: #bdbdbd;
}
.fVfcRr {
  display: block !important;
  padding-left: 10px;
}
.fileuploadname {
  font-size: 12px;
}
label[for="file"] {
  max-width: 100% !important;
  min-width: 0 !important;
}
.kiHXWb {
  min-width: 0 !important;
  height: auto !important;
}
.fVfcRr .file-types {
  max-width: none;
}
#expenseForm #expensefield input {
  text-align: left;
  padding: 16.5px 14px;
  height: 23px;
  font-size: 16px;
  max-width: 100%;
  width: 100%;
}
.rmdp-container {
  max-width: 100%;
}
.file-types {
  margin: 0 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.maindiv {
  width: 100%;
}
.noextra,
.fail {
  background: #f37272;
}
.paymentpending {
  background: #10ff00;
  max-height: 22px;
  overflow: hidden;
}
video {
  max-width: 100%;
}
.MuiButton-contained,
.maindiv header,
.MuiSwitch-track {
  background: #00b4aa !important;
}
.MuiTabs-indicator {
  background: #202844 !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ageCount {
  font-size: 14px;
}
.countCount {
  font-weight: bold !important;
}
.notAllBooked {
  background: #ff000052;
}
.totalRow {
  background: #bbbbbb;
}
.weekHide {
  display: none;
}
.weekShow {
  display: block;
}
.totalTable {
  margin-top: 20px;
}
/* .hideweekly p {
  display: none;
}
.hideweekly.tableheader thead,
.hideweekly tr {
  display: none;
}
.hideweekly.tableheader0 thead,
.hideweekly.tableheader0 thead tr,
.hideweekly tr.totalRowAgg {
  display: table-row;
}
.hideweekly tr,
.hideweekly th,
.hideweekly td {
  width: 12% !important;
} */

@media only screen and (max-width: 700px) {
  .referralPage .MuiAccordionSummary-root {
    padding: 0 5px !important;
  }
  .referralPage .makeStyles-heading-10 {
    flex-basis: 60% !important;
  }
  .referralPage .MuiBox-root-18,
  .referralPage .MuiToolbar-gutters {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  /* TABLE STUFF */
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block !important;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative !important;
    padding-left: 50% !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  td:before {
    /* Now like a table header */
    position: absolute !important;
    /* Top/left values mimic padding */
    top: 6px !important;
    left: 6px !important;
    width: 45% !important;
    padding-right: 10px !important;
    white-space: nowrap;
    font-weight: bold;
    text-align: left;
  }
  table {
    min-width: 0 !important;
  }
  .referralPage .css-yuob64 {
    margin: 0 !important;
  }
  .referralTable td {
    padding-left: 35% !important;
    text-align: right;
    width: auto !important;
  }
  .referralTable td:before {
    width: 35% !important;
  }
  /*
	Label the data
	*/
  .referralTable td:nth-of-type(1):before {
    content: "Date";
  }
  .referralTable td:nth-of-type(2):before {
    content: "Physician";
  }
  .referralTable td:nth-of-type(3):before {
    content: "Direct mail";
  }
  .referralTable td:nth-of-type(4):before {
    content: "Advertising";
  }
  .referralTable td:nth-of-type(5):before {
    content: "Former patient";
  }
  .referralTable td:nth-of-type(6):before {
    content: "Patient";
  }
  .referralTable td:nth-of-type(7):before {
    content: "Other";
  }
  .referralTable td:nth-of-type(8):before {
    content: "Total Referrals";
  }
  .referralTable td:nth-of-type(9):before {
    content: "Referrals w/ Appts";
  }
  .referralTable td {
    text-align: right;
  }
  .referralTable .guestsTime input {
    text-align: right;
  }
  .referralTableTotal td:nth-of-type(1):before {
    content: "Week of";
  }

  .formerPatientTable td {
    padding-left: 35% !important;
    text-align: right;
    width: auto !important;
  }
  .formerPatientTable td:before {
    width: 35% !important;
  }
  /*
	Label the data
	*/

  .formerPatientTable td:nth-of-type(1):before {
    content: "Name";
  }
  .formerPatientTable td:nth-of-type(2):before {
    content: "DOB";
  }
  .formerPatientTable td:nth-of-type(3):before {
    content: "Devices";
  }
  .formerPatientTable td:nth-of-type(4):before {
    content: "Loss Left";
  }
  .formerPatientTable td:nth-of-type(5):before {
    content: "Loss Right";
  }
  .formerPatientTable td:nth-of-type(6):before {
    content: "Address";
  }
  .formerPatientTable td:nth-of-type(7):before {
    content: "Phone";
  }
  .formerPatientTable td:nth-of-type(8):before {
    content: "Email";
  }
  .formerPatientTable td {
    text-align: right;
    min-height: 30px;
  }
}

.rbc-allday-cell {
  display: none;
}
.rbc-time-view .rbc-header {
  border-bottom: none;
}
.np {
  background: #d7ffeb !important;
  color: #000 !important;
}
.fllwup {
  background: #f8dcee !important;
  color: #000 !important;
}
.npt {
  background: #f0dcfd !important;
  color: #000 !important;
}
.lunch {
  background: #d7ffff !important;
  color: #000 !important;
}
.hc {
  background: #bccabc !important;
  color: #000 !important;
}
.ts {
  background: #fff9d7 !important;
  color: #000 !important;
}
.annualha {
  background: #ffe8d7 !important;
  color: #000 !important;
}
.npbd {
  background: #ffd7d7 !important;
  color: #000 !important;
}
.twodc {
  background: #d7d7d7 !important;
  color: #000 !important;
}
.np-no {
  background: #fff5f7 !important;
  color: #000 !important;
}
.hap {
  background: #f7dfda !important;
  color: #000 !important;
}
.bandd {
  background: #fcfaeb !important;
  color: #000 !important;
}
.wr {
  background: #f1dede !important;
  color: #000 !important;
}
.admin {
  background: #ffffd7 !important;
  color: #000 !important;
}
.annualnon {
  background: #ffffd7 !important;
  color: #000 !important;
}

.rdp-row td:nth-child(7) .rdp-day_selected,
.rdp-row td:nth-child(1) .rdp-day_selected {
  background: #ff0000 !important;
}
.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  background-color: rgb(0, 180, 170) !important;
}
.pendingrow {
  background: #f8f88d !important;
}
.conflictvacay {
  background: #fc6464 !important;
}
.totalRowSales {
  background: #8798ad !important;
  color: #fff !important;
}
